<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{ $t("admin.settings.hero.title") }}

      <CButton
        color="primary"
        square
        size="sm"
        class="float-right"
        @click="store"
      >
        {{ $t("buttons.save") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CAlert color="danger" :fade="false" v-if="Object.entries(errors).length">
        {{ errors.message }}
        <ul>
          <li v-for="(error, field) in errors.errors" :key="field">
            {{ error[0] }}
          </li>
        </ul>
      </CAlert>
      <CRow>
        <CCol sm="6">
          <CInputFile
            :label="$t('admin.settings.hero.image_desktop_en')"
            @change="imageDesktopEnUploaded"
          />
          <StorageImage :path="images.image_desktop.en" _class="w-100" />
        </CCol>
        <CCol sm="6">
          <CInputFile
            :label="$t('admin.settings.hero.image_mobile_en')"
            @change="imageMobileEnUploaded"
          />
          <StorageImage :path="images.image_mobile.en" _class="w-100" />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="6">
          <CInputFile
            :label="$t('admin.settings.hero.image_desktop_fr')"
            @change="imageDesktopFrUploaded"
          />
          <StorageImage :path="images.image_desktop.fr" _class="w-100" />
        </CCol>
        <CCol sm="6">
          <CInputFile
            :label="$t('admin.settings.hero.image_mobile_fr')"
            @change="imageMobileFrUploaded"
          />
          <StorageImage :path="images.image_mobile.fr" _class="w-100" />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import { makeFormData } from "@/helpers";
import StorageImage from "@/components/StorageImage";

export default {
  components: {
    StorageImage
  },

  data() {
    return {
      isLoading: true,
      errors: [],
      form: {
        name: "",
        for: "homepage-hero",
        image_desktop: {},
        image_mobile: {}
      },
      images: {
        image_desktop: {},
        image_mobile: {}
      }
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.$http.get(`/api/admin/homepage-hero`).then(response => {
        this.form = response.data.data;
        if (this.form.image_desktop) {
          this.images.image_desktop.en = this.form.image_desktop.en;
          this.images.image_desktop.fr = this.form.image_desktop.fr;
          delete this.form.image_desktop.en;
          delete this.form.image_desktop.fr;
        } else {
          this.form.image_desktop = {};
        }
        if (this.form.image_mobile) {
          this.images.image_mobile.en = this.form.image_mobile.en;
          this.images.image_mobile.fr = this.form.image_mobile.fr;
          delete this.form.image_mobile.en;
          delete this.form.image_mobile.fr;
        } else {
          this.form.image_mobile = {};
        }
        this.isLoading = false;
      });
    },
    store() {
      this.isLoading = true;
      this.errors = [];

      this.$http
        .post(`/api/admin/homepage-hero`, makeFormData(this.form, true), {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(() => location.reload())
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isLoading = false));
    },
    imageDesktopEnUploaded(files) {
      this.form.image_desktop.en = files[0];
    },
    imageDesktopFrUploaded(files) {
      this.form.image_desktop.fr = files[0];
    },
    imageMobileEnUploaded(files) {
      this.form.image_mobile.en = files[0];
    },
    imageMobileFrUploaded(files) {
      this.form.image_mobile.fr = files[0];
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.settings.hero.title")
    };
  }
};
</script>
