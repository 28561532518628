<template>
  <img :src="storagePath" :class="_class" v-if="path" />
</template>

<script>
export default {
  props: {
    path: String,
    _class: String
  },

  computed: {
    storagePath() {
      return "/storage/" + this.path;
    }
  }
};
</script>
