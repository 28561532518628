<template>
  <CRow align-horizontal="center">
    <CCol sm="4">
      <CCard>
        <div class="loader" v-if="isLoading">
          <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
        </div>

        <CCardHeader>
          {{ $t("admin.settings.title") }}

          <CButton
            color="primary"
            variant="outline"
            square
            size="sm"
            class="float-right"
            @click="save"
          >
            {{ $t("buttons.save") }}
          </CButton>
        </CCardHeader>
        <CCardBody>
          <CAlert
            color="danger"
            :fade="false"
            v-if="Object.entries(errors).length"
          >
            {{ errors.message }}
            <ul>
              <li v-for="(error, field) in errors.errors" :key="field">
                {{ error[0] }}
              </li>
            </ul>
          </CAlert>
          <template v-if="settings.length > 0">
            <div class="form-row form-group">
              <label class="col-form-label col-sm-4">
                {{ getNameByKey("extra_shop") }}
              </label>
              <div
                class="col-sm-8 d-flex align-items-center justify-content-end"
              >
                <CSwitch
                  color="primary"
                  size="sm"
                  :checked="form.extra_shop"
                  @update:checked="form.extra_shop = $event"
                />
              </div>
            </div>
          </template>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      errors: [],
      settings: [],
      form: {}
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      return this.$http.get("/api/admin/settings").then(response => {
        this.settings = response.data.data;
        this.form = Object.assign(
          {},
          ...this.settings.map(setting => {
            return { [setting.key]: setting.value };
          })
        );
        this.isLoading = false;
      });
    },
    getNameByKey(key) {
      return this.settings.find(setting => setting.key === key).name;
    },
    save() {
      this.isLoading = true;
      this.errors = [];

      this.$http
        .put(`/api/admin/settings`, this.form)
        .then(() => this.getData())
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isLoading = false));
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.settings.title")
    };
  }
};
</script>
